import 'swiper/swiper-bundle.min.css';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import '../css/load.scss';

import './jquery-ui.min.js';
import Swiper from 'swiper/swiper-bundle.min.mjs';
import { Fancybox } from '@fancyapps/ui';

(function ($) {
	const $win = $(window);
	const $doc = $(document);

	padKartSection();
	padPilotSection();
	initHeaderMenuToggler();
	initSlider('.slider-karts');
	initFancyBoxAlbum('album');

	function addOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').addClass('no-scroll');
		$overlay.addClass('active');
	}

	function removeOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').removeClass('no-scroll');
		$overlay.removeClass('active');
	}

	function scrollToSection() {
		$('a.btn-scroller').on('click', function (e) {
			let sectionId = $(this).attr('href');
			if (!sectionId.startsWith('#')) {
				return;
			}

			e.preventDefault();

			$('html, body').animate(
				{
					scrollTop: $(sectionId).offset().top - 40
				},
				1000
			);
		});
	}

	function initHeaderMenuToggler() {
		if (!$('.header__menu-toggle').length) {
			return;
		}

		const $header = $('.header__container');

		$('.header .header__menu-toggle').on('click', function (e) {
			$(this).closest('.header__container').toggleClass('menu-open');
			addOverlay('.app-loader-overlay');
			e.preventDefault();
		});

		$('.header__menu-close svg').on('click', function (e) {
			$('.header__container').toggleClass('menu-open');
			e.preventDefault();
			removeOverlay('.app-loader-overlay');
		});

		$doc.mouseup(function (e) {
			if ($header.hasClass('menu-open')) {
				if (!$header.is(e.target) && $header.has(e.target).length === 0) {
					$header.removeClass('menu-open');
					removeOverlay('.app-loader-overlay');
				}
			}
		});
	}

	function padKartSection() {
		if (!$('.section-wide .section__inner').length) {
			return;
		}

		let tempBodyWidth = $win.width();
		if (tempBodyWidth <= 1025) {
			return;
		}

		$win.on('load resize', function () {
			let bodyWidth = $win.width();
			let $parent = $('.section-wide');

			if (bodyWidth <= 1025) {
				$parent.find('.section__inner').css('paddingRight', 0);
				return;
			}

			let bigShellWidth = $parent.find('.shell.shell--alt').width();
			let smallShellWidth = $('.shell:not(.shell--alt,.shell--wild)').width();
			let paddingInt = Math.floor(bigShellWidth - smallShellWidth) / 2;

			$parent.find('.section__inner').css('paddingRight', paddingInt);
		});
	}

	function padPilotSection() {
		if (!$('.shell--wild').length) {
			return;
		}

		let tempBodyWidth = $win.width();
		if (tempBodyWidth > 1400 || tempBodyWidth <= 1025) {
			return;
		}

		$win.on('load resize', function () {
			let bodyWidth = $win.width();
			if (bodyWidth > 1400 || bodyWidth <= 1025) {
				$('.shell--wild .section__body').css('paddingLeft', 0);
				return;
			}

			let smallShellWidth = $('.shell:not(.shell--alt, .shell--wild)').width();
			let paddingInt = Math.floor(bodyWidth - smallShellWidth) / 2;

			$('.shell--wild .section__body').css('paddingLeft', paddingInt);
		});
	}

	function initSlider(elementSelector) {
		const swiper = new Swiper('.swiper' + elementSelector, {
			loop: true,
			slidesPerView: 1,
			spaceBetween: 16,

			pagination: {
				el: '.swiper-pagination'
			},
			navigation: {
				nextEl: `${elementSelector} + .swiper-buttons .swiper-button-next`,
				prevEl: `${elementSelector} + .swiper-buttons .swiper-button-prev`
			}
		});
	}

	function initFancyBoxAlbum(dataSelector) {
		const options = {
			Thumbs: {
				type: 'classic'
			}
		};

		Fancybox.bind(`[data-fancybox="${dataSelector}"]`, options);
	}
})(jQuery);
